import * as React from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import { useState } from 'react';
import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';

import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';

import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { borderRadius, Stack } from '@mui/system';
import { useLocales } from 'src/locales';

const cardData = [
  {
    id: 1,
    html: 'EASY_TO_USE_ANYWHERE_ANYTIME',
    title: 'Easy to Use',
    subTitle: 'Anywhere, AnyTime',
    icon: 'typcn:thumbs-ok',
  },
  {
    id: 2,
    title: 'Book',
    html: 'BOOK_INSTANTLY',
    subTitle: 'Instantly',
    icon: 'tdesign:book',
  },
  {
    id: 3,
    title: 'Personalized',
    html: 'PERSONALIZED_EXPERIENCE',
    subTitle: 'Experience',
    icon: 'mdi:user-star-outline',
  },
  {
    id: 4,
    title: 'Enjoy Your',
    html: 'ENJOY_YOUR_SERVICES',
    subTitle: 'Services',
    icon: 'fluent-mdl2:manager-self-service',
  },
];

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function OurFeatures() {
  const theme = useTheme();
  const mdUp = useResponsive('up', 'md');

  const { currentLang, t } = useLocales();

  return (
    <>
      <Container
        component={MotionViewport}
        fluid
        sx={{
          padding: '20px',
          background: theme.palette.background.default,
        }}
      >
        <m.span variants={varFade().inUp}>
          <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
            <Typography
              component="div"
              variant="h2"
              sx={{ mb: 5, textAlign: 'center' }}
            >
              <div dangerouslySetInnerHTML={{ __html: t('OUR_FEATURES') }} />
            </Typography>
          </Box>

          <Grid container spacing={3} justifyContent="center" alignItems={'center'}>
            <Grid item xs={12} md={12} sx={{ display: mdUp ? 'none' : 'block' }}>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <Stack spacing={1}>
                  {cardData?.map((iter, index) => (
                    <Item
                      sx={{
                        backgroundColor: 'transparent',
                        // boxShadow: '0px 0px 5px rgba(33, 33, 33, 0.6)',
                        // borderRadius: '.5rem',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                    >
                      <Iconify
                        color={'#7955CF'}
                        sx={{
                          zIndex: 1,
                          backgroundColor: '#fafafa',
                          border: '2px solid #7955CF',
                          borderRadius: '50%',
                          padding: '.5rem',
                        }}
                        width={55}
                        height={60}
                        // marginRight={'.-5rem'}
                        icon={iter.icon}
                      />
                      <Typography
                        variant="h6"
                        sx={{
                          marginLeft: '-3rem',
                          zIndex: 0,
                          fontWeight: [700],
                          border: '2px solid #7955CF',
                          borderRadius: '5rem',
                          display: 'flex',
                          alignItems: 'center',
                          width: '100%',
                        }}
                      >
                        <div style={{ width: '3rem', height: '2rem' }}></div>

                        <div dangerouslySetInnerHTML={{ __html: t(iter.html) }} />
                      </Typography>
                    </Item>
                  ))}
                </Stack>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              md={9}
              sx={{ display: mdUp ? 'flex' : 'none', justifyContent: 'center' }}
            >
              <m.span variants={varFade().inUp}>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {cardData?.slice(0, 2).map((iter, index) => (
                    <Box
                      sx={{
                        border: '1px solid #424242',
                        display: 'inline-flex',
                        borderRadius: '.5rem',
                        minWidth: '12rem',
                        padding: 2,
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Iconify
                        color={'#7955CF'}
                        width={40}
                        marginRight={'.5rem'}
                        icon={iter.icon}
                      />
                      <Typography marginTop={'.5rem'} textAlign={'center'} variant="subtitle1">
                        <div dangerouslySetInnerHTML={{ __html: t(iter.html + '_2') }} />
                      </Typography>
                    </Box>
                  ))}
                </Box>
                <Box
                  sx={{
                    marginTop: '-5rem',
                    marginBottom: '-2rem',
                  }}
                  padding={'1rem 7rem'}
                >
                  <img src={'/assets/images/landing/home/features.png'} alt="image" />
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                  {cardData?.slice(2, 4).map((iter, index) => (
                    <Box
                      sx={{
                        border: '1px solid #424242',
                        display: 'inline-flex',
                        borderRadius: '.5rem',
                        minWidth: '12rem',
                        padding: 2,
                        justifyContent: 'center',
                        flexDirection: 'column',
                        alignItems: 'center',
                      }}
                    >
                      <Iconify
                        color={'#7955CF'}
                        width={30}
                        marginRight={'.5rem'}
                        icon={iter.icon}
                      />
                      <Typography marginTop={'.5rem'} textAlign={'center'} variant="subtitle1">
                        <div dangerouslySetInnerHTML={{ __html: t(iter.html + '_2') }} />
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </m.span>
            </Grid>
          </Grid>
        </m.span>
      </Container>
    </>
  );
}
