import PropTypes from 'prop-types';
import { useRef } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import { Link, useParams } from 'react-router-dom';
import Card from '@mui/material/Card';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import InputBase from '@mui/material/InputBase';
import Grid from '@mui/material/Unstable_Grid2';
import CardHeader from '@mui/material/CardHeader';
// _mock
import { _socials } from 'src/_mock';
// utils
import { fNumber } from 'src/utils/format-number';
// components
import Iconify from 'src/components/iconify';
//
import ProfilePostItem from './profile-post-item';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import { useTheme } from '@emotion/react';
import Typography from '@mui/material/Typography';
import { useGetAllBusinessQuery, useGetBusinessByIdQuery } from 'src/store/Reducer/business';
import { Avatar, IconButton, ListItemText, Skeleton, Tooltip } from '@mui/material';
import DisplaySubcategories from '../category/subcategories';
import DisplayBusinessSubcategories from './display-business-subcategories';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';
import { useBoolean } from 'src/hooks/use-boolean';
import SettingsFormModal from './settingsModal';
// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function ProfileHome() {
  const { branch } = useMockedBranch();

  const quickCreate = useBoolean();

  const fileRef = useRef(null);

  const { user } = useMockedUser();

  const { data, status } = useGetBusinessByIdQuery(branch._id);

  const { socialLinks } = data ?? {};

  const handleAttach = () => {
    if (fileRef.current) {
      fileRef.current.click();
    }
  };

  return (
    <>
      <Grid container spacing={3}>
        <Box sx={{ml:3}}>
          <Typography variant="h5" gutterBottom>
            {'Information'}
          </Typography>
          <Card
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              p: 2,
              borderRadius: 2,
              boxShadow: 3,
              backgroundColor: 'background.paper',
              mb: 2,
            }}
          >
            <Typography variant="body2" color="text.secondary">
              {branch?.description || 'This business has not provided a information yet.'}
            </Typography>
          </Card>
        </Box>
        <Grid
          xs={12}
          md={12}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'start',
            p: (theme) => theme.spacing(2, 3, 3, 3),
          }}
        >
          <Box>
            <Typography color="info" variant="h5" sx={{ mb: 2 }}>
              Categories
            </Typography>
            {status === 'pending' ? (
              <Skeleton variant="rounded" maxWidth={'100%'} height={'6rem'} />
            ) : (
              <DisplayBusinessSubcategories subcategories={data?.categories} />
            )}{' '}
          </Box>

          <Box>
            {user?.user_type === 2 && (
              <Button onClick={() => quickCreate.onTrue()} variant="contained" color="primary">
                Setting
              </Button>
            )}
          </Box>
        </Grid>

        <Grid
          xs={12}
          md={4}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            p: (theme) => theme.spacing(2, 3, 3, 3),
          }}
        >
          <Typography color="info" variant="h5" sx={{ mb: 2 }}>
            About
          </Typography>
          {status === 'pending' ? (
            <Stack spacing={2}>
              <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
              <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
              <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
              <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
              <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
              <Skeleton variant="rounded" maxWidth={'100%'} height={'2rem'} />
            </Stack>
          ) : (
            <Stack spacing={2}>
              <Item sx={{ display: 'flex' }}>
                <Iconify icon="solar:call-chat-rounded-bold-duotone" sx={{ mr: 2 }} />
                <Typography>{data?.contactNumber}</Typography>
              </Item>

              <Item sx={{ display: 'flex' }}>
                <Iconify icon="dashicons:email" sx={{ mr: 2 }} />
                <Typography>{data?.business?.email}</Typography>
              </Item>

              {[
                { icon: 'devicon:facebook', link: socialLinks?.facebook, label: 'facebook' },
                {
                  icon: 'skill-icons:instagram',
                  link: socialLinks?.instagram,
                  label: 'instagram',
                },
                // { icon: 'logos:twitter', link: socialLinks?.twitter, label: 'twitter' },
                { icon: 'devicon:linkedin', link: socialLinks?.linkedin, label: 'linkedin' },
              ].map((social, idx) => (
                <Item sx={{ display: 'flex' }} key={idx}>
                  <Iconify icon={social.icon} color="#64b5f6" sx={{ flexShrink: 0, mr: 2 }} />
                  <a
                    href={`${social.link}` ?? '#'}
                    style={{ textDecoration: 'none', color: '#1976d2', fontWeight: 'bold' }}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {social.link ? social.link : `Provide Social Links`}
                  </a>
                </Item>
              ))}

              <Item sx={{ display: 'flex' }}>
                <Iconify icon="solar:map-point-wave-bold-duotone" sx={{ mr: 2 }} />
                <Tooltip title={data?.address?.line1}>
                  <Typography
                    sx={{
                      maxWidth: '15rem',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {data?.address?.line1}
                  </Typography>
                </Tooltip>
              </Item>

              {/* {data?.description && (
                <Item sx={{ display: 'flex' }}>
                  <Iconify icon="f7:doc-on-clipboard-fill" sx={{ mr: 2 }} />
                  <Typography>{data?.description}</Typography>
                </Item>
              )} */}
            </Stack>
          )}
        </Grid>
      </Grid>

      {quickCreate.value && (
        <SettingsFormModal  open={quickCreate.value} onClose={quickCreate.onFalse} />
      )}
    </>
  );
}

ProfileHome.propTypes = {
  info: PropTypes.object,
  posts: PropTypes.array,
};
