import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import { useResponsive } from 'src/hooks/use-responsive';
import {
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { RHFTextField } from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAddNewPreBusinessMutation } from 'src/store/Reducer/pre_signup_business';
import FormProvider from 'src/components/hook-form/form-provider';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'src/components/snackbar';
import { useSettingsContext } from 'src/components/settings';
import FaqsDownloadsList from '../faqs-Downloads-list ';
import Iconify from 'src/components/iconify/iconify';
import { useState } from 'react';

export default function ReasonBecomePartner() {
  const theme = useTheme();

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const { enqueueSnackbar } = useSnackbar();

  const mdSm = useResponsive('up', 'sm');
  const mdXs = useResponsive('up', 'sx');
  const lgUp = useResponsive('up', 'lg');

  console.log('mdSm - ', mdSm);

  const settings = useSettingsContext();

  const { t } = useLocales();

  return (
    <Box sx={{ backgroundColor: theme.palette.mode === 'light' ? '#FBF9FF' : '' }}>
      <Grid
        component={MotionViewport}
        container
        sx={{
          display: 'flex',
          marginBottom: '5rem',

          justifyContent: 'center',
        }}
      >
        <Grid item xs={11} md={11}>
          <m.span variants={varFade().inUp}>
            <Box
              display={'flex'}
              sx={{
                maxHeight: '15rem',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <Typography
                gutterBottom
                fontWeight={900}
                sx={{
                  fontSize: {
                    lg: '13rem',
                    md: '13rem',
                    sm: '12rem',
                    xs: '12rem',
                  },

                  background: 'linear-gradient(100deg, #7955CF 40%, #f5f5f5 100%)',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                }}
              >
                3
              </Typography>
              <Typography
                gutterBottom
                variant="h1"
                sx={{
                  marginLeft: '1rem',
                  maxWidth: {
                    lg: '20rem',
                    md: '20rem',
                  },
                  minWidth: {
                    lg: '40rem',
                    md: '40rem',
                  },

                  textShadow: '1px 1px 3px rgba(33, 33, 33, 0.5)', // Add a subtle shadow to enhance the boldness
                }}
                fontWeight={900}
              >
                <div dangerouslySetInnerHTML={{ __html: t('REASON_TO_BECOME_PARTNER') }} />
              </Typography>
            </Box>
          </m.span>
        </Grid>

        <Grid item xs={11} md={2}>
          <m.span variants={varFade().inUp}>
            {!mdSm ? (
              CardData?.map((faq, idx) => (
                <Accordion key={idx} expanded={expanded === idx} onChange={handleChange(idx)}>
                  <AccordionSummary
                    expandIcon={
                      <Iconify
                        icon={
                          expanded === idx
                            ? 'solar:alt-arrow-down-bold'
                            : 'solar:alt-arrow-down-bold'
                        }
                        sx={{ margin: '2rem 0rem' }}
                      />
                    }
                  >
                    <Typography
                      variant="h4"
                      sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
                    >
                      <div dangerouslySetInnerHTML={{ __html: t(`${faq.title}`) }} />
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                      backgroundColor: 'tranparent',
                    }}
                  >
                    <Typography>{t(faq.description)}</Typography>
                  </AccordionDetails>
                </Accordion>
              ))
            ) : (
              <FaqsDownloadsList allFaqs={CardData} />
            )}
          </m.span>
        </Grid>
        <Grid
          item
          xs={11}
          md={4}
          sx={{
            marginTop: {
              xs: '0rem',
              sm: '0rem',
              md: '0rem',
              lg: '0rem',
            },
          }}
        >
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              loading="lazy"
              style={{
                objectFit: 'cover',
                padding: '0rem',
                aspectRatio: '13 / 9',
              }}
              // src={'/assets/images/landing/partner/becomePartner.png'}
              src={'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727075068399.png'}
              alt="Image"
            />
          </Box>
        </Grid>
      </Grid>
      <Divider
        sx={{
          marginX: '20rem',
          position: 'relative',
          // width: '80%',
          marginTop: '5rem',
          height: '.6rem',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            background: 'linear-gradient(100deg, #7955CF 50%, #f5f5f5 100%)',
            borderRadius: '0.7rem',
          },
        }}
      />
    </Box>
  );
}

const CardData = [
  {
    id: 0,
    title: 'BUILD_YOUR_BUSINESS',
    icon: 'ic:baseline-local-offer',
    description:
      'Expand and manage your offerings effortlessly. Join Timezzi to create a comprehensive service catalog and grow your business.',
  },
  {
    id: 1,
    title: 'BUILD_YOUR_TEAM',
    icon: 'ri:team-fill',
    description:
      "Create, manage, and empower your ideal dream team with Timezzi's seamless tools and support.",
  },
  {
    id: 2,
    title: 'BUILD_YOUR_SUCCESS',
    icon: 'fluent:arrow-growth-20-filled',
    description:
      "Unlock your potential with Timezzi's powerful tools and achieve unstoppable growth.",
  },
];
