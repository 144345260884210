import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { admin_paths, partner_paths } from 'src/config-global';

export default function IndexGuard({ children }) {
  const { user } = useMockedUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (user?.email) {
      // alert('You are already logged in');
      const redirectPath = user?.user_type === 2 ? partner_paths : admin_paths;
      console.log('redirectPath = ', redirectPath);
      
      navigate(redirectPath, { replace: true });
    }
  }, [user, navigate]);

  return <>{children}</>;
}
