import { useScroll } from 'framer-motion';
import React from 'react';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// components
import ScrollProgress from 'src/components/scroll-progress';
//

import { Container, Typography, useTheme } from '@mui/material';
import HomeHero from '../home/home-hero';
import OurFeatures from '../home/home-looking-for';

import CustomerBusiness from '../home/customer-business';
// import moment from 'moment-timezone';
import { useTranslation } from 'react-i18next';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { useAddVisitorsMutation } from 'src/store/Reducer/dashboard';
import NewReasonToJoin from '../home/newReasonToJoin';
import CustomerFaqs from '../home/customerFaqs';
import WhereInnovationMeetsConvenience from '../home/where-innovation-meets';
import SectionSlider1 from '../home/section-slider1';
import SectionSlider2 from '../home/section-slider2';
import HomePricing from '../pricing/pricing';
// ----------------------------------------------------------------------
// import LandingVideoSection from '../home/LandingVideo-Section';
const LandingVideoSection = React.lazy(() => import('../home/LandingVideo-Section'));
export default function TimezziLandingHomeView() {
  const { scrollYProgress } = useScroll();
  const theme = useTheme();

  console.log('SessionStorage = ', window.sessionStorage);

  const [addVisitors] = useAddVisitorsMutation();

  const initializeVisitCount = useCallback(async () => {
    try {
      if (!sessionStorage.getItem('visited')) {
        const response = await addVisitors();

        console.log('response = ', response);

        sessionStorage.setItem('visited', response?.data?.body?.visitCount?.toString());
      }
    } catch (error) {
      console.log(error);
    }
  }, []);

  useEffect(() => {
    initializeVisitCount();
  }, [initializeVisitCount]);

  const { t, i18n } = useTranslation();

  const userTimezone = Intl.DateTimeFormat().resolvedOptions();
  const userLanguage = navigator.language || 'en';

  console.log(navigator);

  return (
    <>
      <ScrollProgress scrollYProgress={scrollYProgress} />
      <LandingVideoSection />
      <HomeHero />
      <NewReasonToJoin />
      <SectionSlider1 />
      <WhereInnovationMeetsConvenience />
      <SectionSlider2 />
      <CustomerFaqs />
      {/* <HomePricing /> */}
      <CustomerBusiness />
    </>
  );
}
