import { useMemo } from 'react';
// routes
import { paths } from 'src/routes/paths';
// locales
import { useLocales } from 'src/locales';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import SvgColor from 'src/components/svg-color';
import { useGetAllBusinessQuery, useGetBusinessByIdQuery } from 'src/store/Reducer/business';
import { useState } from 'react';
import { useEffect } from 'react';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';

// ----------------------------------------------------------------------

const icon = (name) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  job: icon('ic_job'),
  blog: icon('ic_blog'),
  chat: icon('ic_chat'),
  mail: icon('ic_mail'),
  user: icon('ic_user'),
  file: icon('ic_file'),
  lock: icon('ic_lock'),
  tour: icon('ic_tour'),
  order: icon('ic_order'),
  label: icon('ic_label'),
  blank: icon('ic_blank'),
  kanban: icon('ic_kanban'),
  folder: icon('ic_folder'),
  banking: icon('ic_banking'),
  booking: icon('ic_booking'),
  invoice: icon('ic_invoice'),
  product: icon('ic_product'),
  calendar: icon('ic_calendar'),
  disabled: icon('ic_disabled'),
  external: icon('ic_external'),
  menuItem: icon('ic_menu_item'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  dashboard: icon('ic_dashboard'),
  agents: icon('ic_agents'),
  equipments: icon('ic_equipments'),
  services: icon('ic_services'),
  category: icon('ic_category'),
  offers: icon('ic_offers'),
  discount: icon('ic_discount'),
  campaigns: icon('ic_compaigns'),
  gift: icon('ic_gift'),
  carts: icon('ic_carts'),
  chats: icon('ic_chats'),
  marketing: icon('ic_marketing'),
};

// ----------------------------------------------------------------------

export function usePartnerNavData() {
  const { t } = useLocales();

  const { branch } = useMockedBranch();

  const data = useMemo(
    () => [
      {
        subheader: t('overview'),
        items: [
          {
            title: t('Dashboard'),
            path: paths.partner.root,
            icon: ICONS.dashboard,
          },
          {
            title: t('Business'),
            path: `${paths.partner.business.profile}/${branch?._id}/profile`,
            icon: ICONS.blog,
          },
          {
            title: t('marketing tools'),
            path: `${paths.partner.marketing.profile}/${branch?._id}/discounts`,
            icon: ICONS.marketing,
          },
          // {
          //   title: t('Customers'),
          //   path: paths.partner.customer.root,
          //   icon: ICONS.user,
          // },
          // {
          //   title: t('Sponsorships'),
          //   path: paths.partner.sponsorships.root,
          //   icon: ICONS.user,
          // },
          // {
          //   title: t('FAQs'),
          //   path: paths.partner.faqs,
          //   icon: ICONS.menuItem,
          // },
          // {
          //   title: t('About Us'),
          //   path: paths.partner.aboutUs,
          //   icon: ICONS.tour,
          // },
          // {
          //   title: t('Privacy Policy'),
          //   path: paths.partner.privacy,
          //   icon: ICONS.lock,
          // },
          // {
          //   title: t('Terms & Conditions'),
          //   path: paths.partner.terms,
          //   icon: ICONS.kanban,
          // },
          {
            title: t('Contact Us'),
            path: paths.partner.contactUs,
            icon: ICONS.chat,
          },
        ],
      },
    ],
    [t]
  );

  return data;
}
