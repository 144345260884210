import PropTypes from 'prop-types';
// @mui
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
// components
import Label from 'src/components/label';
import { Avatar, IconButton, ListItemText, Tooltip } from '@mui/material';
import { format } from 'date-fns';
import Iconify from 'src/components/iconify';
import { useBoolean } from 'src/hooks/use-boolean';
import { usePopover } from 'src/components/custom-popover';
import { useRouter } from 'src/routes/hooks';
import { paths } from 'src/routes/paths';
import { useParams } from 'react-router-dom';
import { fShortenNumber } from 'src/utils/format-number';
// ----------------------------------------------------------------------

export default function UserTableRow({
  row,
  selected,
  onEditRow,
  onSelectRow,
  onDeleteRow,
  index,
  counter,
}) {
  const { services, user, status } = row;

  const params = useParams();

  const router = useRouter();

  const confirm = useBoolean();

  const quickEdit = useBoolean();

  const popover = usePopover();

  const handleClick = () => {
    console.log(row);
  };
  return (
    <>
      <TableRow hover selected={selected} onClick={handleClick}>
        <TableCell>{counter}</TableCell>
        <TableCell sx={{ whiteSpace: 'nowrap' }}>{row?.email}</TableCell>
        <TableCell>
          <ListItemText
            primary={`${format(new Date(row?.createdAt), 'dd MMM yyyy')}`}
            secondary={`${format(new Date(row?.createdAt), 'p')}`}
            primaryTypographyProps={{ typography: 'body2', noWrap: true }}
            secondaryTypographyProps={{
              mt: 0.5,
              component: 'span',
              typography: 'caption',
            }}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

UserTableRow.propTypes = {
  onDeleteRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onSelectRow: PropTypes.func,
  row: PropTypes.object,
  selected: PropTypes.bool,
};
