import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// MUI Components
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  MenuItem,
  Divider,
  Box,
  Typography,
  Checkbox,
  FormControlLabel,
} from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
// Custom Components
import FormProvider, { RHFSelect, RHFTextField } from 'src/components/hook-form';
// Hooks and Utilities
import { useSnackbar } from 'src/components/snackbar';
import { useBusinessSettingMutation, useGetAllCurrenciesQuery } from 'src/store/Reducer/business';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { useMockedBranch } from 'src/hooks/use-mocked-branch';
import { useDispatch } from 'react-redux';
import { setBranch } from 'src/store/slices/userSlice';

export default function SettingsFormModal({ open, onClose }) {
  const { businessID } = useParams();
  const { branch } = useMockedBranch();
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { data: allCurrencies } = useGetAllCurrenciesQuery();

  const [selectedCurrency, setSelectedCurrency] = useState(null);

  const [businessSettingMutation, { isLoading }] = useBusinessSettingMutation();

  // Validation Schema
  const SettingSchema = Yup.object().shape({
    currency: Yup.object()
      .shape({
        code: Yup.string().required('Currency code is required'),
        symbol: Yup.string().required('Currency symbol is required'),
        country: Yup.string().required('Country is required'),
      })
      .required('Currency details are required'),
    // isMain: Yup.boolean(),
    description: Yup.string().required('Description is required'),
    cancellationPeriod: Yup.number().required('Cancellation Period is required'),
    lateCancellationCharge: Yup.number()
      .required('Late Cancellation Charge is required')
      .min(0, 'Charge must be positive'),
    homeService: Yup.object()
      .shape({
        fare: Yup.number().required('Fare is required').min(0, 'Fare must be positive'),
        maxDistance: Yup.number()
          .required('Max distance is required')
          .min(0, 'Distance must be positive'),
      })
      .required('Home Service details are required'),
    slotGap: Yup.number().required('Slot Gap is required'),
  });

  // Form setup
  const methods = useForm({
    resolver: yupResolver(SettingSchema),
    defaultValues: {
      currency: branch?.currency || null,
      // isMain: branch?.isMain || false,
      description: branch?.description || '',
      cancellationPeriod: branch?.cancellationPeriod || 0,
      lateCancellationCharge: branch?.lateCancellationCharge || 0,
      homeService: {
        fare: branch?.homeService?.fare || 0,
        maxDistance: branch?.homeService?.maxDistance || 0,
      },
      slotGap: branch?.slotGap || 0,
    },
  });

  const { handleSubmit, setValue, formState, watch } = methods;
  const { isSubmitting } = formState;
  const watchCurrency = watch('currency');
  // Submit handler
  const onSubmit = async (data) => {
    try {
      const payload = {
        _id: businessID,
        data: {
          currency: data.currency,
          description: data.description,
          cancellationPeriod: data.cancellationPeriod,
          lateCancellationCharge: data.lateCancellationCharge,
          homeService: data.homeService,
          slotGap: data.slotGap,
          // isMain: data.isMain,
        },
      };

      console.log('Payload to submit:', payload);

      // Call the API
      const response = await businessSettingMutation(payload).unwrap();

      if (response.status === 200) {
        dispatch(
          setBranch({
            ...branch,
            ...data,
          })
        );
      }
      enqueueSnackbar('Submitted successfully!', { variant: 'success' });
      onClose();
    } catch (error) {
      console.error('Submission error:', error);

      // Show error message
      enqueueSnackbar(error?.data?.message || 'Failed to submit data!', { variant: 'error' });
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={onClose}
      PaperProps={{ sx: { maxWidth: 720 } }}
    >
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          <Box
            sx={{
              mt: 2,
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
            }}
          >
            {/* Currency Selection */}
            <RHFSelect
              name="currency"
              label="Currency"
              value={watchCurrency?.code || ''} // Pass only the unique identifier (_id)
              onChange={(e) => {
                const selectedOption = allCurrencies.find(
                  (currency) => currency.code === e.target.value
                );
                setSelectedCurrency(selectedOption); // Save the full object in local state
                setValue('currency', {
                  code: selectedOption.code,
                  symbol: selectedOption.symbol,
                  country: selectedOption.country,
                }); // Save only required fields in form state
              }}
            >
              <MenuItem value="">None</MenuItem>
              <Divider sx={{ borderStyle: 'dashed' }} />
              {allCurrencies?.map((option) => (
                <MenuItem key={option.code} value={option.code}>
                  {`${option.code} - ${option.symbol} - ${option.country}`}
                </MenuItem>
              ))}
            </RHFSelect>

            {/* Other Fields */}
            <RHFTextField
              name="cancellationPeriod"
              label="Cancellation Period (hrs)"
              type="number"
            />
            <RHFTextField
              name="lateCancellationCharge"
              label="Late Cancellation Charge (%)"
              type="number"
            />
            <RHFTextField name="slotGap" label="Slot Gap (min)" type="number" />
          </Box>

          {/* Home Services Section */}
          <Typography variant="subtitle2" sx={{ mt: 2, mb: 1 }}>
            Home Services
          </Typography>
          <Box
            sx={{
              display: 'grid',
              rowGap: 3,
              columnGap: 2,
              gridTemplateColumns: { xs: '1fr', sm: '1fr 1fr' },
            }}
          >
            <RHFTextField name="homeService.fare" label={`Home Service Fare (${watchCurrency.code})`} type="number" />
            <RHFTextField
              name="homeService.maxDistance"
              label="Home Service Max Distance (km)" 
              type="number"
            />
          </Box>

          {/* Description and Is Main */}
          <Box sx={{ mt: 2 }}>
            <RHFTextField name="description" label="Description" multiline rows={4} />
            {/* <FormControlLabel
              control={<Checkbox {...methods.register('isMain')} />}
              label="Is Main"
              sx={{ mt: 2 }}
            /> */}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={onClose}>
            Cancel
          </Button>
          <LoadingButton type="submit" variant="contained" loading={isSubmitting}>
            Submit
          </LoadingButton>
        </DialogActions>
      </FormProvider>
    </Dialog>
  );
}

SettingsFormModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};
