import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import { RHFTextField } from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { color, m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';

import { useSnackbar } from 'src/components/snackbar';
import { useSettingsContext } from 'src/components/settings';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/iconify';
import { borderRadius, padding, useMediaQuery } from '@mui/system';

export default function RevoloutionizeYourReach() {
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const is1450px = useMediaQuery('(min-width:1450px)');

  const mdSm = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  const settings = useSettingsContext();

  const { currentLang, t } = useLocales();

  return (
    <Grid
      component={MotionViewport}
      container
      sx={{
        margin: '5rem 0',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Grid
        item
        xs={11}
        md={5}
        order={{ xs: 2, md: 2, lg: 2 }}
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <img
            style={{
              objectFit: 'contain',
              aspectRatio: '5 / 5',
            }}
            loading="lazy"
            // src={'/assets/images/landing/partner/reach.png'}
            src={'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727075264117.png'}
            alt="Image"
          />
        </Box>
      </Grid>

      <Grid
        item
        xs={11}
        md={4}
        sm={12}
        order={{ xs: 1, md: 1, lg: 1 }}
        display={'grid'}
        alignItems={'center'}
        sx={{
          marginLeft: {
            md: '5rem',
            xs: '0',
          },
        }}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            gutterBottom
            variant="h2"
            fontWeight={900}
            sx={{
              textShadow: '1px 1px 3px rgba(33, 33, 33, 0.5)', // Add a subtle shadow to enhance the boldness
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: t('REVOLUTIONIZE_YOUR_REACH') }} />
          </Typography>
          <Typography gutterBottom variant="h6" fontWeight={400} maxWidth={'45rem'}>
            <div dangerouslySetInnerHTML={{ __html: t('TIMEZZI_PLATFORM') }} />
          </Typography>
          <Typography gutterBottom variant="h6" fontWeight={900} maxWidth={'45rem'}>
            {t('Unlock 30+ Powerful Features')}
          </Typography>
        </Box>
      </Grid>
    </Grid>
  );
}
