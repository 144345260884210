import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const NewLetterApi = createApi({
  reducerPath: 'services',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['newLetter'],
  endpoints: (builder) => ({

    getAllNewLetter: builder.query({
      query: ({ search, pageno, limit, branch }) =>
        `/newsletter/subscribe?branch=${branch}&search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => res.body,
      providesTags: ['newLetter'],
    }),

  }),
});

export const {
  useGetAllNewLetterQuery,
} = NewLetterApi;
