import React, { useState } from 'react';
import Accordion from '@mui/material/Accordion';
import Typography from '@mui/material/Typography';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Iconify from 'src/components/iconify';
import { Box, CircularProgress } from '@mui/material';
import { margin, useMediaQuery } from '@mui/system';
import { useEffect } from 'react';
import { useLocales } from 'src/locales';
import { useSettingsContext } from 'src/components/settings';
import { useRef } from 'react';

// ----------------------------------------------------------------------

export default function FaqsDownloadsList({ allFaqs }) {
  const [loading, setLoading] = useState(false);

  const { t } = useLocales();

  const is500px = useMediaQuery('(min-width:500px)');

  console.log('allFaqs = ', allFaqs);

  const [expandedIndex, setExpandedIndex] = useState(null);
  const tooltipRefs = useRef([]);

  const handleMouseEnter = (idx) => {
    if (tooltipRefs.current[idx]) {
      tooltipRefs.current[idx].style.display = 'block';
    }
  };

  const handleMouseLeave = (idx) => {
    if (tooltipRefs.current[idx]) {
      tooltipRefs.current[idx].style.display = 'none';
    }
  };

  console.log(tooltipRefs);

  return (
    <Box display={'flex'} justifyContent={'center'} alignItems={'start'} flexDirection={'column'}>
      {loading && <CircularProgress />}

      {allFaqs?.map((faq, idx) => (
        <Box
          sx={{ display: 'flex', alignItems: 'center', position: 'relative' }}
          key={idx}
          onMouseEnter={() => handleMouseEnter(idx)}
          onMouseLeave={() => handleMouseLeave(idx)}
        >
          <Box
            sx={{
              width: '100%',
              margin: '.5rem',
              marginLeft: !is500px && '4rem',
              paddingRight: '.5rem',
              borderBottom: '1px solid #311b92',
              transition: 'all 0.3s ease',
              '&:hover': {
                cursor: 'pointer',
                transition: 'all 0.3s ease',
                border: '1px solid transparent', // Fallback for browsers that don't support border-image
                borderImage: 'linear-gradient(100deg, #311b92 50%, #b39ddb 100%) 1', // Apply the gradient to the border
              },
            }}
          >
            <Box sx={{ display: 'flex' }}>
              <Iconify
                sx={{
                  transition: 'transform 0.2s ease',
                  margin: '1rem 1rem',
                  color: '#7955CF',
                }}
                icon={faq.icon}
                width={24}
              />
              <Typography
                variant="h6"
                fontWeight={500}
                sx={{ display: 'flex', alignItems: 'center' }}
              >
                <div dangerouslySetInnerHTML={{ __html: t(`${faq.title}`) }} />
              </Typography>
            </Box>
          </Box>
          <Box
            ref={(el) => (tooltipRefs.current[idx] = el)}
            sx={{
              boxShadow: 3,
              position: 'absolute',
              left: '100%',
              marginLeft: '1rem', // Add some spacing between the boxes
              display: 'none',
              pointer: 'cursor',
              borderRadius: '1rem',
              backgroundColor: 'rgba(237, 231, 246, 1)',
              padding: '10px 15px',
              zIndex: 100,
              transform: 'translateY(-10px)', // Start slightly above for slide-in effect
              transition: 'opacity 0.3s ease, transform 0.3s ease', // Smooth transition for opacity and position
            }}
          >
            <Box>
              <Typography
                variant="subtitle2"
                sx={{ fontWeight: [200], width: '20rem' }}
                color={'#212B36'}
              >
                {t(faq.description)}
              </Typography>
            </Box>
            <Box
              sx={{
                position: 'absolute',
                width: 2,
                borderRight: '15px solid rgba(237, 231, 246, 1)',
                borderBottom: '15px solid transparent',
                borderTop: '15px solid transparent',
                top: '35%',
                marginLeft: '-1.5rem',
              }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
}
