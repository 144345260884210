import PropTypes from 'prop-types';
import { addDays } from 'date-fns';
import { useState, useCallback, useEffect } from 'react';
// @mui
import MenuItem from '@mui/material/MenuItem';
import Box from '@mui/material/Box';
import ButtonBase from '@mui/material/ButtonBase';
import CardHeader from '@mui/material/CardHeader';
import Card from '@mui/material/Card';
// components
import Iconify from 'src/components/iconify';
import Chart, { useChart } from 'src/components/chart';
import CustomPopover, { usePopover } from 'src/components/custom-popover';
import CustomDateRangePicker, { useDateRangePicker } from 'src/components/custom-date-range-picker';
import { useGetBusinessAnalyticsDashboardQuery } from 'src/store/Reducer/dashboard';
import { Button, DialogActions } from '@mui/material';
import axios from 'axios';
import { useMockedUser } from 'src/hooks/use-mocked-user';
import moment from 'moment-timezone';

// ----------------------------------------------------------------------

export default function EcommerceYearlySales({ title, subheader, chart, ...other }) {
  const { colors } = chart;
  const popover = usePopover();
  const user = useMockedUser();
  // const [seriesData, setSeriesData] = useState('2019');s
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const formatDate = (date) => {
    if (!date) return '';
    return date instanceof Date && !isNaN(date) ? date?.toISOString()?.split('T')[0] : '';
  };

  const formatDisplayDate = (date) => {
    return date ? date?.toLocaleDateString() : '';
  };

  const { data: chartData } = useGetBusinessAnalyticsDashboardQuery({
    from: formatDate(startDate),
    to: formatDate(endDate ? addDays(endDate, 1) : endDate),
  });
  let categories = chartData?.bookings?.map((item) => moment(item?.date || item?.createdAt).format("MMM Do"));
  const options = {
    chart: {
      id: 'bookings-report',
      toolbar: {
        show: false,
      },
    },
    xaxis: {
      categories: categories,
    },
    yaxis: {
      min: 0, // Ensure the Y-axis starts from 0
      title: {
        text: 'Number of Bookings',
      },
    },
    stroke: {
      curve: 'smooth',
    },
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 0.4,
        opacityFrom: 0.7,
        opacityTo: 0.1,
        stops: [0, 90, 100],
      },
    },
    tooltip: {
      enabled: true,
    },
    legend: {
      show: true,
    },
    title: {
      text: 'Bookings & Cancelled Report',
      align: 'center',
    },
  };

  const chartOptions = useChart({
    colors,
    legend: {
      position: 'top',
      horizontalAlign: 'right',
    },
    xaxis: {
      categories,
    },
    ...options,
  });

  // const handleChangeSeries = useCallback(
  //   (newValue) => {
  //     popover.onClose();
  //     setSeriesData(newValue);
  //   },
  //   [popover]
  // );

  const rangeCalendarPicker = useDateRangePicker(new Date(), null);

  const handleApplyDateRange = () => {
    setStartDate(rangeCalendarPicker.startDate);
    setEndDate(rangeCalendarPicker.endDate);
    rangeCalendarPicker.onClose();
  };

  const series = [
    {
      name: 'Total Bookings',
      data: chartData?.bookings?.map((item) => item?.totalBookings ||0),
      color: '#6f42c1', // Purple for bookings
    },
    {
      name: 'Cancelled Bookings',
      data: chartData?.bookings?.map((item) => item?.cancelledBookings ||0),
      color: '#fd7e14', // Orange for cancellations
    },
  ];
  const handleDownloadReport = async () => {
    try {
      const config = {
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${user?.user?.accesstoken}`,
          'Content-Type': 'application/json',
        },
      };

      // Format the dates
      const from = formatDate(startDate);
      const to = formatDate(endDate ? addDays(endDate, 1) : endDate);

      const response = await axios.get(
        `${process.env.REACT_APP_HOST_API}/business//analytics/report?from=${from}&to=${to}`,
        config
      );

      const blob = new Blob([response?.data], { type: 'application/pdf' });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `report.pdf`;
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('There was an error making the request:', error);
    }
  };

  return (
    <>
      <Card {...other}>
        <CardHeader
          title={title}
          subheader={subheader}
          action={
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              {startDate && endDate && (
                <Box sx={{ mr: 2 }}>
                  {formatDisplayDate(startDate)} - {formatDisplayDate(endDate)}
                </Box>
              )}
              <ButtonBase
                onClick={rangeCalendarPicker.onOpen}
                sx={{
                  pl: 1,
                  py: 0.5,
                  pr: 0.5,
                  borderRadius: 1,
                  typography: 'subtitle2',
                  bgcolor: 'background.neutral',
                }}
              >
                Choose Date
                <Iconify
                  width={16}
                  icon={popover.open ? 'eva:arrow-ios-upward-fill' : 'eva:arrow-ios-downward-fill'}
                  sx={{ ml: 0.5 }}
                />
              </ButtonBase>
            </Box>
          }
        />

        <Box sx={{ mt: 3, mx: 3 }}>
          <Chart dir="ltr" type="line" options={chartOptions} series={series} height={350} />
        </Box>
      </Card>

      <CustomDateRangePicker
        variant="calendar"
        open={rangeCalendarPicker.open}
        startDate={rangeCalendarPicker.startDate}
        endDate={rangeCalendarPicker.endDate}
        onChangeStartDate={rangeCalendarPicker.onChangeStartDate}
        onChangeEndDate={rangeCalendarPicker.onChangeEndDate}
        onClose={rangeCalendarPicker.onClose}
        error={rangeCalendarPicker.error}
        handleApplyDateRange={handleApplyDateRange}
      />

      <DialogActions>
        <Button
          disabled={startDate === null || endDate === null}
          variant="contained"
          onClick={handleDownloadReport}
        >
          Downlaod
        </Button>
      </DialogActions>

      {/* <CustomPopover open={popover.open} onClose={popover.onClose} sx={{ width: 140 }}>
        {['2019', '2020', '2021', '2022'].map((year) => (
          <MenuItem
            key={year}
            selected={year === seriesData}
            onClick={() => handleChangeSeries(year)}
          >
            {year}
          </MenuItem>
        ))}
      </CustomPopover> */}
    </>
  );
}

EcommerceYearlySales.propTypes = {
  chart: PropTypes.object,
  subheader: PropTypes.string,
  title: PropTypes.string,
};
