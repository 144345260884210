import { Helmet } from 'react-helmet-async';
// sections
import MarketingProfileView from 'src/sections/business/view/marketing-profile-view';

// ----------------------------------------------------------------------

export default function MarketingPage({ children }) {
  return (
    <>
      <Helmet>
        <title> Dashboard: Marketing Tools</title>
      </Helmet>

      <MarketingProfileView children={children} />
    </>
  );
}
