import React, { useState } from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import {
  Button,
  DialogActions,
  DialogContent,
  Icon,
  IconButton,
  InputAdornment,
  InputBase,
  Menu,
  Paper,
  TextField,
  Tooltip,
} from '@mui/material';

import { RHFTextField } from 'src/components/hook-form';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import FormProvider from 'src/components/hook-form/form-provider';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Carousel, { CarouselArrows, useCarousel } from 'src/components/carousel';
import { useRef } from 'react';
import Slider from 'react-slick';
import '../dotsStyle.css';
import '../arrowStyle.css';
import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';
import { useLocales } from 'src/locales';

export default function SectionSlider1() {
  const sliderRef = useRef(null);

  const theme = useTheme();

  const { t } = useLocales();

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // autoplay: true,
    speed: 300,
    // autoplaySpeed: 3000,
    // cssEase: 'linear',
    // rtl: true,
  };

  return (
    <>
      <Grid
        component={MotionViewport}
        container
        spacing={5}
        justifyContent="center"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: {
            xs: '5% 0',
            sm: '5% 0',
            md: '5% 0',
            lg: '5% 0',
          },
          marginX: 'auto',
        }}
      >
        <Grid item xs={11} md={11} lg={11}>
          <Slider ref={sliderRef} {...settings}>
            {cardData.map((card, index) => (
              <CarousalItem t={t} key={card.id} card={card} index={index} />
            ))}
          </Slider>
        </Grid>
      </Grid>
    </>
  );
}

function CarousalItem({ card, index, t }) {
  return (
    <Grid
      component={MotionViewport}
      container
      spacing={5}
      justifyContent="center"
      sx={{
        display: 'flex',
        marginX: 'auto',
        marginBottom: '5rem',
      }}
    >
      <Grid
        item
        xs={12}
        md={5}
        order={{ xs: 1, md: 2 }}
        display={'grid'}
        alignItems={'center'}
        sx={{
          minHeight: {
            xs: '25rem',
          },
        }}
      >
        <Box display={'flex'} flexDirection={'column'}>
          <Typography
            sx={{
              // fontSize: '4rem', // Adjust the size to be larger if needed
              textShadow: '1px 1px 3px rgba(33, 33, 33, 0.5)', // Add a subtitle shadow to enhance the boldness
            }}
            gutterBottom
            variant="h1"
            fontWeight={800}
          >
            <div dangerouslySetInnerHTML={{ __html: t(card?.localesText) }} />

            {/* {t(card?.title)} <span style={{ color: '#7955cf' }}>{t(card?.subtitle)}</span> */}
          </Typography>

          {index === 0 ? (
            <Box display="flex" gap={0.7} sx={{ marginBottom: '.5rem' }}>
              <Iconify width={24} icon="fluent-emoji-flat:star" />
              <Iconify width={24} icon="fluent-emoji-flat:star" />
              <Iconify width={24} icon="fluent-emoji-flat:star" />
              <Iconify width={24} icon="fluent-emoji-flat:star" />
              <Iconify width={24} icon="fluent-emoji-flat:star" />
            </Box>
          ) : (
            ''
          )}
          <Typography gutterBottom variant="h5" fontWeight={400}>
            {t(card?.description)}
          </Typography>
        </Box>
      </Grid>
      <Grid
        sx={{
          marginTop: {
            lg: '0rem',
            md: '0rem',
            sm: '-3rem',
            xs: '-3rem',
          },
        }}
        item
        xs={12}
        md={4}
        order={{ xs: 2, md: 2 }}
      >
        <m.span variants={varFade().inUp}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <img
              style={{
                width: '25rem',
                objectFit: 'contain',
                aspectRatio: '5 / 5',
              }}
              loading="lazy"
              // src={`/assets/images/landing/about/aboutSlider1/${card?.id}.png`}
              src={card?.src}
              alt="Image"
            />
          </Box>
        </m.span>
      </Grid>
    </Grid>
  );
}

CarousalItem.propTypes = {
  item: PropTypes.object,
};

const cardData = [
  {
    title: 'Trusted',
    localesText: 'Trusted_Professionals',
    src: 'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727075769112.png',
    subtitle: 'Professionals',
    description: `We collaborate with vetted and experienced professionals to guarantee top-quality services every time. Browse verified reviews and ratings, and book appointments at your convenience.`,

    id: 1,
  },
  {
    id: 2,
    title: 'Effortless ',
    localesText: 'Effortless_Access',
    src: 'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727075811623.png',
    subtitle: 'Access',
    description: `Create a profile to receive personalized service recommendations based on your preferences and booking history. Enjoy a tailored experience that suits your unique lifestyle needs.`,
  },

  {
    id: 3,
    title: 'Custom',
    localesText: 'Custom_Comfort',
    src: 'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727075829303.png',
    subtitle: 'Comfort',
    description: `Book appointments anytime, anywhere, with our user-friendly mobile app. Say goodbye to long waits and phone calls -- Schedule your next service in just a few taps.`,
  },
  {
    id: 4,
    title: 'Exclusive',
    localesText: 'Exclusive_Offers',
    src: 'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727075794450.png',
    subtitle: 'Offers',
    description: `Take advantage of special offers and discounts only available through Timezzi. Save money while enjoying first class services.`,
  },
];
