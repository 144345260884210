import { createApi } from '@reduxjs/toolkit/query/react';
import { createCustomFetchBaseQuery } from '../baseQuery';

export const serviceApi = createApi({
  reducerPath: 'serviceApi',
  baseQuery: createCustomFetchBaseQuery(),
  tagTypes: ['Services'],
  endpoints: (builder) => ({
    getAllService: builder.query({
      query: ({ search, pageno, limit, branch }) =>
        `/services?branch=${branch}&search=${search}&pageno=${pageno + 1}&limit=${limit}`,
      transformResponse: (res) => res.body,
      providesTags: ['Services'],
    }),

    getServicesForDropDown: builder.query({
      query: ({branch}) => `services?branch=${branch}`,
      transformResponse: (res) => res.body,
    }),

    getServiceById: builder.query({
      query: (id) => `/services/${id}`,
      transformResponse: (res) => res.body,
      providedTags: ['Services'],
    }),

    addNewService: builder.mutation({
      query: (newService) => ({
        url: `/services`,
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: newService,
      }),
      invalidatesTags: ['Services'],
    }),

    getServicebySearch: builder.query({
      query: () => `/service/all`,
      transformResponse: (res) => res.body,
    }),

    updateOfferType: builder.mutation({
      query: ({ _id, updatedData }) => ({
        url: `/services/${_id}/offer`,
        method: 'Put',
        headers: { 'Content-Type': 'application/json' },
        body: updatedData,
      }),
      invalidatesTags: ['Services'],
    }),

    updateService: builder.mutation({
      query: ({ _id, updatedData }) => ({
        url: `/services/${_id}`,
        method: 'Put',
        headers: { 'Content-Type': 'application/json' },
        body: updatedData,
      }),
      invalidatesTags: ['Services'],
    }),
  }),
});

export const {
  useGetAllServiceQuery,
  useAddNewServiceMutation,
  useUpdateServiceMutation,
  useUpdateOfferTypeMutation,
  useGetServicebySearchQuery,
  useGetServicesForDropDownQuery,
  useGetServiceByIdQuery,
} = serviceApi;
