import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { borderRadius, flexbox, maxWidth, useMediaQuery, width } from '@mui/system';
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { RHFTextField } from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAddNewPreBusinessMutation } from 'src/store/Reducer/pre_signup_business';
import FormProvider from 'src/components/hook-form/form-provider';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'src/components/snackbar';
import { useSettingsContext } from 'src/components/settings';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

export default function PartnerFAQs() {
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const is500px = useMediaQuery('(min-width:500px)');

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const mdXs = useResponsive('up', 'sx');
  const lgUp = useResponsive('up', 'lg');

  const settings = useSettingsContext();

  const { t } = useLocales();
  return (
    <>
      <Grid
        component={MotionViewport}
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          margin: '10% 0',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          item
          xs={11}
          md={6}
          sx={{
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            gutterBottom
            variant="h2"
            sx={{
              textShadow: '1px 1px 3px rgba(33, 33, 33, 0.5)', // Add a subtle shadow to enhance the boldness
            }}
            fontWeight={900}
          >
            <div dangerouslySetInnerHTML={{ __html: t('Frequently_Ask_Questions') }} />
          </Typography>
          <Typography gutterBottom variant="h6" sx={{ marginTop: '2rem' }} fontWeight={400}>
            {t('Everything you need to know about Timezzi Partner')}
          </Typography>
        </Grid>

        <Grid item xs={11} md={6} sx={{ marginTop: '2rem' }}>
          {FaqsData?.map((faq, idx) => (
            <Accordion key={idx} expanded={expanded === idx} onChange={handleChange(idx)}>
              <AccordionSummary
                expandIcon={
                  <Iconify
                    icon={expanded === idx ? 'tabler:minus' : 'tabler:plus'}
                    sx={{ margin: '2rem 0rem' }}
                  />
                }
              >
                <Typography
                  variant="subtitle1"
                  sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
                >
                  {t(faq.title)}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  backgroundColor: 'tranparent',
                }}
              >
                <Typography>{t(faq.message)}</Typography>
              </AccordionDetails>
            </Accordion>
          ))}
        </Grid>
      </Grid>
    </>
  );
}

const FaqsData = [
  {
    title: 'What is Timezzi?',
    message:
      'Timezzi is an innovative platform designed to streamline scheduling and booking processes for lifestyle services businesses. It helps businesses efficiently manage appointments, resources, and client information.',
  },
  {
    title: 'How does timezzi benefit my business?',
    message:
      'Timezzi empowers your business by simplifying scheduling tasks, optimizing operations, and enhancing customer satisfaction. It provides tools to attract new clients, increase revenue, and foster growth opportunities.',
  },
  {
    title: 'Can I access timezzi from anywhere?',
    message: `Yes, timezzi is a cloud-based platform accessible from any device with an internet connection. Whether you're in the salon, at home, or on the go, you can manage your schedule and bookings with ease using timezzi's mobile-friendly interface.`,
  },
  {
    title: 'How can I get started with timezzi?',
    message: `Getting started with timezzi is quick and easy! Simply sign up for an account, customize your settings, and start managing your schedule and bookings right away. Our onboarding process is seamless, and we're here to guide you every step of the way.`,
  },
];
