import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Checkbox from '@mui/material/Checkbox';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import OutlinedInput from '@mui/material/OutlinedInput';
import FormHelperText from '@mui/material/FormHelperText';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';

// ----------------------------------------------------------------------

export function RHFSelect({
  name,
  native,
  maxHeight = 220,
  helperText,
  children,
  PaperPropsSx,
  ...other
}) {
  const { control } = useFormContext();

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          select
          fullWidth
          SelectProps={{
            native,
            MenuProps: {
              PaperProps: {
                sx: {
                  ...(!native && {
                    maxHeight: typeof maxHeight === 'number' ? maxHeight : 'unset',
                  }),
                  ...PaperPropsSx,
                },
              },
            },
            sx: { textTransform: 'capitalize' },
          }}
          error={!!error}
          helperText={error ? error?.message : helperText}
          {...other}
        >
          {children}
        </TextField>
      )}
    />
  );
}

RHFSelect.propTypes = {
  PaperPropsSx: PropTypes.object,
  children: PropTypes.node,
  helperText: PropTypes.object,
  maxHeight: PropTypes.number,
  name: PropTypes.string,
  native: PropTypes.bool,
};

// ----------------------------------------------------------------------

export function RHFMultiSelect({
  name,
  chip,
  label,
  options,
  checkbox,
  placeholder,
  helperText,
  sx,
  ...other
}) {
  const { control } = useFormContext();

  const renderValues = (selectedIds) => {

    const selectedItems = options?.filter((item) => selectedIds.includes(item?.value))||[];

    if (!selectedItems.length && placeholder) {
      return (
        <Box component="em" sx={{ color: 'text.disabled' }}>
          {placeholder}
        </Box>
      );
    }

    if (chip) {
      return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
          {selectedItems?.map((item) => (
            <Chip key={item.value} size="small" label={item.label} />
          ))}
        </Box>
      );
    }

    return selectedItems?.map((item) => item.label).join(', ');
  };
  return (
    <Controller
  name={name}
  control={control}
  defaultValue={[]}
  render={({ field, fieldState: { error } }) => (
    <FormControl sx={sx}>
      {label && <InputLabel id={name}> {label} </InputLabel>}

      <Select
        {...field}
        multiple
        displayEmpty={!!placeholder}
        labelId={name}
        input={<OutlinedInput fullWidth label={label} error={!!error} />}
        renderValue={renderValues}
        {...other}
      >
        {/* {placeholder && (
          <MenuItem disabled value="">
            <em> {placeholder} </em>
          </MenuItem>
        )} */}
        
        {options?.map((option) => {
          const selected = field?.value?.includes(option.value);
          return (
            <MenuItem key={option?.value} value={option?.value}>
              {checkbox && <Checkbox size="small" disableRipple checked={selected} />}
              {option?.label}
            </MenuItem>
          );
        })}
      </Select>

      {(!!error || helperText) && (
        <FormHelperText error={!!error}>{error ? error?.message : helperText}</FormHelperText>
      )}
    </FormControl>
  )}
/>
  );
}

RHFMultiSelect.propTypes = {
  checkbox: PropTypes.bool,
  chip: PropTypes.bool,
  helperText: PropTypes.object,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.array,
  placeholder: PropTypes.string,
  sx: PropTypes.object,
};
