import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';

import { borderRadius, flexbox, maxWidth, useMediaQuery, width } from '@mui/system';
import Iconify from 'src/components/iconify';
import { useResponsive } from 'src/hooks/use-responsive';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  DialogActions,
  DialogContent,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  Paper,
  TextField,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { RHFTextField } from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';

import * as Yup from 'yup';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { useAddNewPreBusinessMutation } from 'src/store/Reducer/pre_signup_business';
import FormProvider from 'src/components/hook-form/form-provider';
import { LoadingButton } from '@mui/lab';

import { useSnackbar } from 'src/components/snackbar';
import { useSettingsContext } from 'src/components/settings';
import { useEffect } from 'react';
import { useMemo } from 'react';
import { useState } from 'react';
import AddIcon from '@mui/icons-material/Add';

export default function CustomerFaqs() {
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();
  const is500px = useMediaQuery('(min-width:500px)');

  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const mdXs = useResponsive('up', 'sx');
  const lgUp = useResponsive('up', 'lg');

  const settings = useSettingsContext();

  const { t } = useLocales();
  return (
    <>
      <Grid
        component={MotionViewport}
        container
        sx={{
          backgroundColor: theme.palette.mode === 'light' ? '#FBF9FF' : '',
          display: 'flex',
          marginTop: {
            lg: '10rem',
            md: '10rem',
            sm: '5rem',
            xs: '5rem',
          },
          flexDirection: 'column',
          padding: '3% 0',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Grid
          item
          xs={11}
          md={6}
          sx={{
            flexDirection: 'column',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <m.span variants={varFade().inUp}>
            <Typography
              gutterBottom
              textAlign={'center'}
              variant="h2"
              sx={{
                textShadow: '1px 1px 3px rgba(33, 33, 33, 0.5)', // Add a subtle shadow to enhance the boldness
              }}
              fontWeight={900}
            >
              <div dangerouslySetInnerHTML={{ __html: t('Frequently_Ask_Questions') }} />
            </Typography>
            <Typography
              textAlign={'center'}
              gutterBottom
              variant="h6"
              sx={{ marginTop: '2rem' }}
              fontWeight={400}
            >
              {t('Everything you need to know about Timezzi Customer')}
            </Typography>
          </m.span>
        </Grid>

        <Grid item xs={11} md={6} sx={{ marginTop: '2rem' }}>
          <m.span variants={varFade().inUp}>
            {FaqsData?.map((faq, idx) => (
              <Accordion key={idx} expanded={expanded === idx} onChange={handleChange(idx)}>
                <AccordionSummary
                  expandIcon={
                    <Iconify
                      icon={expanded === idx ? 'tabler:minus' : 'tabler:plus'}
                      sx={{ margin: '2rem 0rem' }}
                    />
                  }
                >
                  <Typography
                    variant="subtitle1"
                    sx={{ display: 'flex', alignItems: 'center', fontWeight: 'bold' }}
                  >
                    {t(faq.title)}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                    backgroundColor: 'tranparent',
                  }}
                >
                  <Typography>{t(faq.message)}</Typography>
                </AccordionDetails>
              </Accordion>
            ))}
          </m.span>
        </Grid>
      </Grid>
    </>
  );
}

const FaqsData = [
  {
    title: 'What is Timezzi?',
    message:
      'Timezzi is an innovative platform that streamlines the booking process for various lifestyle services, making it easy for you to schedule appointments with your favorite service professionals.',
  },
  {
    title: 'How does Timezzi benefit me?',
    message:
      'Timezzi simplifies the process of finding and booking lifestyle services, ensuring a seamless experience. It offers features like real-time availability, easy appointment management, and access to verified reviews and ratings.',
  },
  {
    title: 'Is Timezzi free to use?',
    message:
      'Yes, using Timezzi to find and book services is free for customers. You only pay for the services you book, with no hidden fees.',
  },
  {
    title: 'How can I get started with Timezzi?',
    message:
      'Getting started with Timezzi is easy! Simply sign up for a free account, browse the available services, and book your first appointment. Our platform is designed to be intuitive and hassle-free.',
  },
];
