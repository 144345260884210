import { Helmet } from 'react-helmet-async';
// sections
import TimezziLandingHomeView from '../sections/timezzi-Landing-Page/view/timezzi-Landing-Page';

// ----------------------------------------------------------------------

export default function TimezziLandingHomePage() {
  return (
    <>
      <Helmet>
        <title> Timezzi: Book your day - Your way </title>
      </Helmet>

      <TimezziLandingHomeView />
    </>
  );
}
