import React, { useState } from 'react';
import {
  Card,
  Avatar,
  Tooltip,
  ListItemText,
  Box,
  Grid,
  Typography,
  Modal,
  Button,
  Rating,
} from '@mui/material';

const ServiceCards = ({ services }) => {
  const [open, setOpen] = useState(false);
  const [selectedService, setSelectedService] = useState(null);

  const handleOpen = (service) => {
    setSelectedService(service);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedService(null);
  };

  return (
    <>
      <Grid container spacing={2}>
        {services?.map((service) => {
          const { title, images, price, offer, currency } = service;
          return (
            <Grid item xs={12} sm={6} md={4} key={title}>
              <Card
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  p: 3,
                  width: '100%', // Full width inside the grid
                  cursor: 'pointer', // Pointer cursor for interactivity
                }}
                onClick={() => handleOpen(service)} // Open modal on click
              >
                {/* Avatar for the image */}
                <Box sx={{ display: 'flex', alignItems: 'center', mr: 2 }}>
                  <Avatar
                    alt={title}
                    src={images?.[0] || '/placeholder.jpg'}
                    sx={{ width: 48, height: 48, mr: 2 }}
                  />
                  <Tooltip title={title} placement="top">
                    <Typography variant="subtitle2" noWrap>
                      {title}
                    </Typography>
                  </Tooltip>
                </Box>

                {/* Price and Discount */}
                <Box sx={{ marginLeft: 'auto', textAlign: 'right' }}>
                  <ListItemText
                    primary={`${currency?.code} ${price.toFixed(2)}`} // Ensure price formatting
                    secondary={offer?.discount && `${offer.discount} OFF`}
                    primaryTypographyProps={{
                      typography: 'subtitle2',
                      fontWeight: 'bold',
                    }}
                    secondaryTypographyProps={{
                      typography: 'caption',
                      color: 'text.secondary',
                    }}
                  />
                </Box>
              </Card>
            </Grid>
          );
        })}
      </Grid>

      {/* Modal for showing more details */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="service-details-title"
        aria-describedby="service-details-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
            width: '90%',
            maxWidth: 500,
          }}
        >
          {selectedService && (
            <>
              {/* Image Section */}
              <Box
                sx={{
                  mb: 3,
                  textAlign: 'center',
                }}
              >
                <img
                  src={selectedService.images?.[0] || '/placeholder.jpg'}
                  alt={selectedService.title}
                  style={{
                    width: '100%',
                    maxWidth: '200px',
                    borderRadius: '8px',
                    margin: '0 auto',
                  }}
                />
              </Box>

              {/* Title */}
              <Typography id="service-details-title" variant="h6" gutterBottom>
                {selectedService?.title} -{' '}
                {selectedService?.offer?.discount ? (
                  <>
                    {/* Original Price with Strike-through */}
                    <Typography
                      component="span"
                      sx={{
                        textDecoration: 'line-through',
                        color: 'text.secondary',
                        mr: 1, // Add spacing between prices
                      }}
                    >
                      {selectedService.currency?.code} {selectedService.price.toFixed(2)}
                    </Typography>

                    {/* Discounted Price */}
                    <Typography
                      component="span"
                      sx={{
                        color: 'primary.main',
                        fontWeight: 'bold',
                      }}
                    >
                      {selectedService.currency?.code}{' '}
                      {(selectedService.price - selectedService.offer.discount).toFixed(2)}
                    </Typography>
                  </>
                ) : (
                  // Show only the price if no discount
                  `${selectedService.currency?.code} ${selectedService.price.toFixed(2)}`
                )}
              </Typography>
              <Rating value={selectedService?.averageRating || 0} size="small" readOnly />
              {/* </Typography>
              </Typography> */}

              {/* Category and Subcategory */}
              <Box
                sx={{
                  display: 'flex',
                  // justifyContent: 'space-around',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    bgcolor: 'primary.light',
                    color: 'primary.contrastText',
                    px: 2,
                    py: 0.5,
                    borderRadius: '4px',
                  }}
                >
                  {selectedService?.category?.name || 'Category'}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    bgcolor: 'secondary.light',
                    color: 'secondary.contrastText',
                    px: 2,
                    py: 0.5,
                    borderRadius: '4px',
                    ml: 1,
                  }}
                >
                  {selectedService?.subcategory?.name || 'Subcategory'}
                </Typography>
              </Box>

              {/* Duration */}
              <Typography
                variant="body2"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  mb: 2,
                }}
              >
                <strong>Duration:</strong>&nbsp;
                {selectedService?.time || 'N/A'} minutes
              </Typography>
              {/* Description */}
              <Typography id="service-details-description" variant="body1" sx={{ mb: 2 }}>
                <strong>Description:</strong>
              </Typography>
              <Typography variant="body2" sx={{ mb: 2 }}>
                {selectedService.description || 'No description available for this service.'}
              </Typography>
              {/* Close Button */}
              <Box sx={{ mt: 3, textAlign: 'right' }}>
                <Button onClick={handleClose} variant="contained" color="primary">
                  Close
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </>
  );
};

export default ServiceCards;
