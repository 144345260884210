import { Helmet } from 'react-helmet-async';
// sections
import TimezziLandingPartnerView from 'src/sections/timezzi-Landing-Page/view/timezzi-landing-partner';

// ----------------------------------------------------------------------

export default function TimezziLandingPartnerPage() {
  return (
    <>
      <Helmet>
        <title> Timezzi: Book your day - Your way </title>
      </Helmet>

      <TimezziLandingPartnerView />
    </>
  );
}
