import isEqual from 'lodash/isEqual';
import { useState, useCallback, useEffect } from 'react';
// @mui
import sumBy from 'lodash/sumBy';
import { alpha } from '@mui/material/styles';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Card from '@mui/material/Card';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import Container from '@mui/material/Container';
import TableBody from '@mui/material/TableBody';
import IconButton from '@mui/material/IconButton';
import TableContainer from '@mui/material/TableContainer';
// routes
import { paths } from 'src/routes/paths';
import { useRouter } from 'src/routes/hooks';
import { RouterLink } from 'src/routes/components';
// _mock
import { _userList, _roles, USER_STATUS_OPTIONS } from 'src/_mock';
// hooks
import { useBoolean } from 'src/hooks/use-boolean';
// components
import Label from 'src/components/label';
import Iconify from 'src/components/iconify';
import Scrollbar from 'src/components/scrollbar';
import { ConfirmDialog } from 'src/components/custom-dialog';
import { useSettingsContext } from 'src/components/settings';
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs';
import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from 'src/components/table';
//
import UserTableRow from '../user-table-row';
import UserTableToolbar from '../user-table-toolbar';
import UserTableFiltersResult from '../user-table-filters-result';
import { useMockedUser } from 'src/hooks/use-mocked-user';
// import {
//   useGetAllBookingQuery,
//   useLazyGetBookingWithFilterPendingQuery,
// } from 'src/store/Reducer/booking';
import { _bookingsAll } from 'src/_mock';

import { LoadingScreen } from 'src/components/loading-screen';
import { useTheme, width } from '@mui/system';
import InvoiceAnalytic from 'src/sections/payment/invoice-analytic';
import { Box, Divider, LinearProgress, Stack, Typography } from '@mui/material';
import { useGetAllBookingQuery } from 'src/store/Reducer/booking';
import { useRef } from 'react';
import { useGetPreBusinessQuery } from 'src/store/Reducer/pre_signup_business';
import { useGetAllNewLetterQuery } from 'src/store/Reducer/newsletter';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'id', label: 'ID' },
  { id: 'email', label: 'Email' },
  { id: 'createdAt', label: 'Date' },
];

const defaultFilters = {
  name: '',
  role: [],
  status: 'all',
};

// ----------------------------------------------------------------------

export default function NewsLetterView() {
  const { user } = useMockedUser();

  const table = useTable();

  const printRef = useRef(null);

  const [filters, setFilters] = useState(defaultFilters);

  const {
    data: { data, total_length } = {},
    isLoading,
    status,
  } = useGetAllNewLetterQuery({
    pageno: table.page,
    limit: table.rowsPerPage,
    search: filters.name,
  });

  console.log('data', data);

  let count = 0;
  let total = table.page * table.rowsPerPage;

  const handlePrint = () => {
    // Get the specific content to print
    const printContents = printRef.current.innerHTML;

    // Save the original body content
    const originalContents = document.body.innerHTML;

    // Temporarily set the body's content to the specific content
    document.body.innerHTML = printContents;

    const tds = document.querySelectorAll('td');
    tds.forEach(td => {
      td.style.color = "black"
    });
    // Trigger the print dialog
    window.print();

    // Restore the original body content
    document.body.innerHTML = originalContents;

    // Optionally, reload the page to ensure everything is back to normal
    window.location.reload();
  };

  const theme = useTheme();

  const settings = useSettingsContext();

  const router = useRouter();

  const confirm = useBoolean();

  const [tableData, setTableData] = useState(data);

  const dataFiltered = applyFilter({
    inputData: data,
    comparator: getComparator(table.order, table.orderBy),
    filters,
  });

  const denseHeight = table.dense ? 52 : 72;

  const canReset = !isEqual(defaultFilters, filters);

  const notFound = (!total_length && canReset) || !total_length;

  const handleFilters = useCallback(
    (name, value) => {
      table.onResetPage();
      setFilters((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    },
    [table]
  );

  const handleResetFilters = useCallback(() => {
    setFilters(defaultFilters);
  }, []);


  const exportToCSV = () => {
    if (data.length === 0) return;

    const headers = [
      "Email",
    ];

    const rows = data.map((data) => [
      data.email,
    ]);

    const csvContent = [headers, ...rows].map((row) => row.join(",")).join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", `newLetters.csv`);
    link.click();
  };


  return (
    <>
      {isLoading ? (
        <LoadingScreen />
      ) : (
        <>
          <Container maxWidth={settings.themeStretch ? false : 'lg'}>
            <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2} sx={{ mb: 2 }}>
              <CustomBreadcrumbs
                heading="Newsletters"
                links={[
                  { name: 'Dashboard', href: paths.dashboard.root },
                  { name: 'Newsletters' },
                ]}
                sx={{
                  mb: { xs: 3, md: 5 },
                }}
              />

              <Button variant="contained" color='primary' onClick={exportToCSV}>
                Export to CSV
              </Button>
            </Stack>

            <Card>
              <UserTableToolbar
                filters={filters}
                onPrint={handlePrint}
                onFilters={handleFilters}
                roleOptions={_roles}
              />

              {canReset && (
                <UserTableFiltersResult
                  filters={filters}
                  onFilters={handleFilters}
                  onResetFilters={handleResetFilters}
                  results={total_length}
                  sx={{ p: 2.5, pt: 0 }}
                />
              )}

              <TableContainer sx={{ position: 'relative', overflow: 'unset' }}>
                <TableSelectedAction
                  numSelected={table.selected?.length}
                  rowCount={data?.length}
                  onSelectAllRows={(checked) =>
                    table.onSelectAllRows(
                      checked,
                      data?.map((row) => row.id)
                    )
                  }
                  action={
                    <Tooltip title="Delete">
                      <IconButton color="primary" onClick={confirm.onTrue}>
                        <Iconify icon="solar:trash-bin-trash-bold" />
                      </IconButton>
                    </Tooltip>
                  }
                />

                <Scrollbar ref={printRef}>
                  {status === 'pending' ? (
                    <Box sx={{ width: '100%' }}>
                      <LinearProgress />
                    </Box>
                  ) : (
                    <Table size={table.dense ? 'small' : 'medium'} sx={{ minWidth: 960 }}>
                      <TableHeadCustom headLabel={TABLE_HEAD} rowCount={total_length} />
                      <TableBody>
                        {data?.map(
                          (row, index) => (
                            (count = count + 1),
                            (
                              <UserTableRow
                                key={row.id}
                                row={row}
                                selected={table.selected.includes(row.id)}
                                index={index + 1}
                                counter={count + total}
                              />
                            )
                          )
                        )}

                        <TableEmptyRows
                          height={denseHeight}
                          emptyRows={emptyRows(table.page, table.rowsPerPage, total_length)}
                        />
                        {total_length >= 1 ? '' : <TableNoData notFound={notFound} />}
                      </TableBody>
                    </Table>
                  )}
                </Scrollbar>
              </TableContainer>

              <TablePaginationCustom
                count={total_length}
                page={table.page}
                rowsPerPage={table.rowsPerPage}
                onPageChange={table.onChangePage}
                onRowsPerPageChange={table.onChangeRowsPerPage}
              />
            </Card>
          </Container>

          {/* <ConfirmDialog
            open={confirm.value}
            onClose={confirm.onFalse}
            title="Delete"
            content={
              <>
                Are you sure want to delete <strong> {table.selected.length} </strong> items?
              </>
            }
            action={
              <Button
                variant="contained"
                color="error"
                onClick={() => {
                  handleDeleteRows();
                  confirm.onFalse();
                }}
              >
                Delete
              </Button>
            }
          /> */}
        </>
      )}
    </>
  );
}

// ----------------------------------------------------------------------

function applyFilter({ inputData, comparator, filters }) {
  const { email, status, role } = filters;

  const stabilizedThis = inputData?.map((el, index) => [el, index]);

  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  inputData = stabilizedThis?.map((el) => el[0]);

  if (email) {
    inputData = inputData.filter(
      (user) => user && user.email && user.email.toLowerCase()?.indexOf(email.toLowerCase()) !== -1
    );
  }

  if (status !== 'all') {
    inputData = inputData.filter((user) => user.status === status);
  }

  if (role.length) {
    inputData = inputData.filter((user) => role.includes(user.role));
  }

  return inputData;
}
