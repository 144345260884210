import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import { useLocales } from 'src/locales';
import { m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';

export default function Revolutionize() {
  const theme = useTheme();

  const { currentLang, t } = useLocales();

  return (
    <Grid
      component={MotionViewport}
      container
      spacing={5}
      sx={{
        backgroundColor: theme.palette.mode === 'light' ? '#FBF9FF' : '',
        display: 'flex',
        justifyContent: 'center',
        padding: {
          lg: '0',
          xs: '4rem 0',
        },
      }}
    >
      <Grid
        item
        xs={11}
        md={5}
        order={{ xs: 1, md: 1 }}
        display={'grid'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <m.span variants={varFade().inUp}>
          <Box display={'flex'} flexDirection={'column'}>
            <Typography gutterBottom variant="h2" fontWeight={800}>
              <div dangerouslySetInnerHTML={{ __html: t('REVOLUTIONIZING_LIFESTYLE_SERVICE') }} />
            </Typography>
            <Typography gutterBottom variant="h5" fontWeight={400}>
              {t(
                'We provide you with a comprehensive platform that changes the way you manage your business, making luxury and comfort available with just a few taps and being up and running.'
              )}
            </Typography>
          </Box>
        </m.span>
      </Grid>

      <Grid mt={8} item xs={12} md={4} order={{ xs: 2, md: 2 }}>
        <m.span variants={varFade().inUp}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-start',
              alignItems: 'center',
            }}
          >
            <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <img
                loading="lazy"
                // src={'/assets/images/landing/partner/revoluntionizeLifestyle.png'}
                src={'https://timezzi-bucket.s3.amazonaws.com/lp_assets/1727074790220.png'}
                alt="Phone"
                style={{ width: '40rem', height: 'auto', objectFit: 'contain' }}
              />
            </Box>
          </Box>
        </m.span>
      </Grid>
    </Grid>
  );
}
