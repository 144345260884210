import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';

import { useResponsive } from 'src/hooks/use-responsive';

import { RHFTextField } from 'src/components/hook-form';
import { useLocales } from 'src/locales';
import { color, m, motion } from 'framer-motion';
import { MotionViewport, varFade } from 'src/components/animate';

import { useSnackbar } from 'src/components/snackbar';
import { useSettingsContext } from 'src/components/settings';
import { IconButton } from '@mui/material';
import Iconify from 'src/components/iconify';
import { borderRadius, padding, useMediaQuery } from '@mui/system';

export default function WhereInnovationMeetsConvenience() {
  const theme = useTheme();

  const { enqueueSnackbar } = useSnackbar();

  const is1450px = useMediaQuery('(min-width:1450px)');

  const mdSm = useResponsive('up', 'sm');
  const mdUp = useResponsive('up', 'md');

  const settings = useSettingsContext();

  const { currentLang, t } = useLocales();

  return (
    <Grid
      component={MotionViewport}
      container
      sx={{
        marginY: '10rem',
        display: 'flex',
        justifyContent: 'center',
      }}
    >
      <Grid
        item
        xs={12}
        md={10}
        sm={12}
        order={{ xs: 1, sm: 1, md: 1, lg: 1 }}
        display={'grid'}
        alignItems={'center'}
      >
        <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
          <Typography
            sx={{
              // fontSize: '4rem', // Adjust the size to be larger if needed
              textShadow: '1px 1px 3px rgba(33, 33, 33, 0.5)', // Add a subtle shadow to enhance the boldness
            }}
            textAlign={'center'}
            gutterBottom
            variant="h2"
            fontWeight={900}
          >
            <div dangerouslySetInnerHTML={{ __html: t('INNOVATION_MEETS_CONVENIENCE') }} />
          </Typography>
          <Typography gutterBottom variant="h5" textAlign={'center'} fontWeight={400}>
            <div dangerouslySetInnerHTML={{ __html: t('TIMEZZI_DESIGNED') }} />
          </Typography>
        </Box>
      </Grid>

      <Grid
        item
        xs={12}
        md={12}
        sm={12}
        order={{ xs: 2, md: 2, lg: 2 }}
        sx={{
          marginTop: '2rem',
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        {cardData?.map((iter, index) => (
          <Box
            sx={{
              padding: '.5rem',
              margin: '.5rem',
              maxWidth: '20rem',
              minHeight: '7rem',
              borderRadius: '1rem',
              boxShadow: 4,
              border: '1px dotted  #311b92',
            }}
          >
            <Box sx={{ position: 'relative' }}>
              <Typography sx={{ marginLeft: '.5rem', minHeight : '4rem' }} gutterBottom variant="h5" fontWeight={400}>
                {t(iter.description)}
              </Typography>

              <Iconify
                icon={iter.icon}
                width={45}
                sx={{
                  // marginLeft: '85%',
                  borderRadius: '1.5rem',
                  backgroundColor: '#673ab7',
                  padding: '.2rem',
                  color: '#ffffff',
                }}
              />
            </Box>
          </Box>
        ))}
      </Grid>
    </Grid>
  );
}

const cardData = [
  { id: 0, description: 'Book services effortlessly with just a few taps.', icon: 'hugeicons:tap-03' },
  {
    id: 1,
    description: 'Stay informed with instant notifications.',
    icon: 'flowbite:bell-ring-outline',
  },
  { id: 2, description: 'Enjoy daily deals and intuitive interface.', icon: 'vaadin:handshake' },
  {
    id: 3,
    description: 'Access tailored services to meet your needs.',
    icon: 'solar:hand-stars-linear',
  },
];
