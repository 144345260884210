import { useScroll } from 'framer-motion';
// @mui
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
// components
import ScrollProgress from 'src/components/scroll-progress';

import { Container, Typography, useTheme } from '@mui/material';

import PartnerHomeHero from '../partner/home-hero';
import Revolutionize from '../partner/revolutionalize';
import SeamLessIntegration from '../partner/seamless-integration';
import ReasonBecomePartner from '../partner/reasonBecomePartner';
import DownloadBusinessApp from '../partner/download-buisiness-app';
import PartnerFAQs from '../partner/partner-faqs';
import RevoloutionizeYourReach from '../partner/revoloutionizeYourReach';
import WhyUs from '../partner/why-us';

// ----------------------------------------------------------------------

export default function TimezziLandingPartnerView() {
  const { scrollYProgress } = useScroll();
  const theme = useTheme();

  return (
    <>
      <ScrollProgress scrollYProgress={scrollYProgress} />
      <PartnerHomeHero />
      <Revolutionize />
      <SeamLessIntegration />
      <ReasonBecomePartner />
      <WhyUs />
      <RevoloutionizeYourReach />
      <DownloadBusinessApp />

      <PartnerFAQs />
    </>
  );
}
